<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="话题标题"
        :rules="[
          { required: true, message: '请输入话题标题', trigger: 'blur' },
        ]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入话题标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="发布人"
        :rules="[{ required: true, message: '请输入发布人', trigger: 'blur' }]"
        prop="broadcaster"
      >
        <v-input
          placeholder="请输入状态文案"
          :width="width"
          :maxlength="12"
          v-model="form.broadcaster"
        ></v-input>
      </el-form-item>
      <el-form-item
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="onlyTenantAuth"
      >
        <v-radio
          :radioObj="onlyTenantAuthOptions"
          v-model="form.onlyTenantAuth"
          @change="handelSelectOnlyTenantAuth"
        ></v-radio>
      </el-form-item>
      <el-form-item
        v-if="form.onlyTenantAuth == 0"
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'blur' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>
      <el-form-item
        v-show="form.authSpaceTypes && form.onlyTenantAuth == 0"
        prop="authSpaceIds"
        :rules="[
          { required: false, message: '请选择发布范围', trigger: 'blur' },
        ]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="width"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </el-form-item>
      <el-form-item
        label="话题封面"
        :rules="[
          {
            required: true,
            message: '请上传话题封面图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="topicPicture"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.topicPicture"
          tips="建议上传正方形图片"
        />
      </el-form-item>
      <el-form-item
        label="编辑类型"
        prop="editType"
        :rules="[
          { required: true, message: '请选择编辑类型', trigger: 'blur' },
        ]"
      >
        <v-radio :radioObj="radioObj" v-model="form.editType"></v-radio>
      </el-form-item>
      <el-form-item
        v-if="form.editType == 1"
        label="外部链接地址"
        :rules="[
          { required: true, message: '请选择编辑类型', trigger: 'blur' },
        ]"
        prop="externalUrl"
      >
        <v-input
          placeholder="请输入外部链接地址"
          v-model="form.externalUrl"
          :width="400"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="关闭点赞"
        v-if="form.editType != 1"
        :rules="[
          { required: true, message: '请选择是否关闭点赞', trigger: 'change' },
        ]"
        prop="closePraise"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closePraise"
        />
      </el-form-item>

      <el-form-item
        label="关闭评论"
        v-if="form.editType != 1"
        :rules="[
          { required: true, message: '请选择是否关闭评论', trigger: 'change' },
        ]"
        prop="closeComment"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeComment"
        />
      </el-form-item>
      <el-form-item
        label="是否需要签收"
        :rules="[
          { required: true, message: '请选择是否需要签收', trigger: 'blur' },
        ]"
        prop="closeSignin"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeSignin"
        />
      </el-form-item>
      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isTop"
      >
        <v-select :width="width" :options="isTopOps" v-model="form.isTop" />
      </el-form-item>

      <el-form-item
        v-if="form.isTop === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="isTopSort"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.isTopSort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        v-if="form.editType != 1"
        label="话题内容"
        prop="topicContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>

      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>

      <el-form-item
        label="备注"
        :rules="[{ required: false, message: '请输入备注', trigger: 'blur' }]"
        prop="remark"
      >
        <v-input
          placeholder="备注"
          :width="width"
          v-model="form.remark"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="阅览次数"
        :rules="[
          {
            required: false,
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="readCount "
      >
        <v-input
          placeholder="请输入阅览次数"
          v-model="form.readCount"
          min="0"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicURL,
  updateTopicURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
  getBuildListURL,
} from "./api.js";
import {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  userTypeMap,
  setUserTypeOps,
} from "./map.js";

export default {
  name: "partnerForm",
  props: {
    id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      width: 220,
      communityList: [],
      // talentTypeOps: [],
      // talentTypeMap: {},
      // talentUserOps: [],
      // talentUserMap: {},
      // isTopSortOps: [],
      isTopOps: setIsTopOps(),
      isTopMap,
      statusOps: setStatusOps(),
      statusMap,
      submitConfig: {
        queryUrl: getTopicInfoByIdURL,
        submitUrl: "",
      },
      radioObj: [
        {
          name: 0,
          value: "内部编辑",
        },
        {
          name: 1,
          value: "外部链接地址",
        },
      ],
      onlyTenantAuthOptions: [
        {
          name: 0,
          value: "发布范围",
        },
        {
          name: 1,
          value: "仅本租户可见",
        },
      ],
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      form: {
        id: null,
        topicType: 4,
        optUser: undefined, // 用户id
        communityId: undefined, // 园区id
        talentUserId: "",
        closeSignin: 0, //是否需要签收功能
        isTop: 0, //是否置顶
        isTopSort: undefined, // 是否置顶排序
        closeComment: 0, //关闭点赞
        closePraise: 0, //关闭评论
        topicTitle: "", // 话题标题
        topicContent: "", // 富文本内容
        broadcaster: "",
        externalUrl: "",
        editType: 0,
        remark: "", // 备注
        status: undefined,
        topicPicture: "", // 话题封面
        subPicture: "",
        authSpaceIds: "", // 区域id集合
        authSpaceTypes: undefined, // 用户类别id
        readCount: 0,
        onlyTenantAuth: 0, //是否仅本租户可见
        // adminId: null,
      },
      communityId: null,
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
      userTypeOps: setUserTypeOps(),
      isCopy: "",
    };
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("11") && types.includes("10")) {
        result = [...this.allOps];
      } else if (types.includes("11")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("10")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  async mounted() {
    const { id, isCopy } = this.$route.query;
    this.form.id = id;
    this.isCopy = isCopy;
    // 10 居民 11 商户
    await Promise.all([
      this.getBuildList(10),
      this.getBuildList(11),
      this.getBuildList("10,11"),
    ]);
    if (id !== undefined) {
      // this.$nextTick(() => {
      this.$refs.formPanel.getData({
        id: id,
      });
      // });
    }
    this.submitConfig.submitUrl =
      id !== undefined
        ? isCopy == 1
          ? addTopicURL
          : updateTopicURL
        : addTopicURL;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    handelSelectOnlyTenantAuth(value) {
      if (value == 1) {
        this.authSpaceIds = [];
      }
    },
    sectionChange(val) {
      console.log(val, this.form);
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },
    changetalentType(value) {
      console.log(value, 89);
      this.form.talentUserId = "";
      this.getTalentUserList(value);
    },
    update(data) {
      // this.$emit("updateData", data);
      Object.keys(this.form).forEach((key) => {
        if (key === "authSpaceTypes" && !data[key]) {
          this.form[key] = "";
          return;
        }
        this.form[key] = data[key];
      });
      if (this.form.topicPicture) {
        this.form.subPicture = this.form.topicPicture;
      }
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        console.log(item, "++++++++++++++");
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      console.log(this.spacePathIds, this.authSpaceIds, "================");
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        // type = 1 居民 2 商户
        // const maxLevel = type === 4 ? 3 : 2;
        let opt = this.dealDataAddParentIds(res.data);
        // 给res.data添加了treeIds
        // this.spacePathIds.push(...(res.data || []));
        // const ops = [
        //   ...(this.dealDataToTree(res.data, maxLevel)[0]
        //     ? this.dealDataToTree(res.data, maxLevel)[0].children
        //     : []),
        // ];
        console.log(res.data, opt, "opt---");
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    submitBefore() {
      if (!this.form.topicPicture || this.form.topicPicture.length == 0) {
        this.$message.error("请上传商品封面图片");
        return;
      }

      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }
      if (this.isCopy == 1) {
        this.form.id = null;
      }
      // if (this.id) {
      //   this.form.id = this.id;
      // }
      // 区域id集合有值表明非全选，反之就是全选
      // this.form.allSpaces = this.form.authSpaceIds.length === 0 ? 1 : 0;
      return true;
    },
    submitSuccess() {
      // this.$emit("updateData", data, true);
      return true;
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
}
</style>
